import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  InputGroupAddon,
} from 'reactstrap';
import Web3 from 'web3';
import Safe, { EthersAdapter } from '@safe-global/protocol-kit';
import { ethers } from 'ethers';

import { truncStringPortion } from '../../helpers/formatter.js';
import Breadcrumbs from '../../components/Common/Breadcrumb.js';
import {
  fetchPolygonconfig,
  dictAliases,
  registerERC20Proposal,
  registerERC20Operation,
  gnosisSafeInfo,
  gnosisSafePendingTxs,
} from '../../helpers/api.js';
import PolygonLogo from '../../components/Common/PolygonLogo.js';
import OpModal from './Modals/OpModal.js';
import SuccessModal from './Modals/SuccessModal.js';
import { MATIC_CHAIN_ID } from '../../constants.js';
import SafeApiKit from '@safe-global/api-kit';

const descLimit = 140;

class PolygonRemoveGnosisSigner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: null,
      owners: null,
      balances: {},
      isSigner: null,
      signer: null,
      processing: false,
      cfgPolygon: {},
      aliases: [],
      displayAmount: null,
      displayResult: null,
      description: null,
      info: 'Connecting to Ledger Nano...',
      descCharLeft: descLimit,
      newSigner: null,
      threshold: 2,
    };
  }

  componentDidMount = async () => {
    const role = this.props.match.params.role;
    let cfgPolygon = await fetchPolygonconfig();
    await this.setState({ cfgPolygon: cfgPolygon.data, role });
    const res = await dictAliases();
    this.setState({ aliases: res.data });
    const safeAddr =
      role === 'administrator'
        ? this.state.cfgPolygon.administrator
        : role === 'minter'
        ? this.state.cfgPolygon.master_minter
        : role === 'owner'
        ? this.state.cfgPolygon.owner
        : this.state.cfgPolygon.reserve;
    const resp = await gnosisSafeInfo(MATIC_CHAIN_ID, safeAddr);
    let balances = {};
    for (let owner of resp.data.owners) {
      balances[owner.value] = await this.fetchBalance(owner.value);
    }
    let respmtx = await gnosisSafePendingTxs(MATIC_CHAIN_ID, safeAddr);
    const query = new URLSearchParams(this.props.location.search);
    let nonce =
      query.get('nonce') || resp.data.nonce + respmtx.data.results.length;
    this.setState({
      role: role,
      owners: resp.data.owners,
      balances: balances,
      threshold: resp.data.threshold,
      nonce,
    });
  };

  connectWallet = async (owner) => {
    this.setState({ signer: owner });
  };

  createOP = async () => {
    this.setState({ processing: true, displayResult: null, error: null });

    const provider = new ethers.BrowserProvider(window.ethereum);
    const safeOwner = await provider.getSigner();
    if (this.state.signer !== safeOwner.address) {
      this.setState({ isSigner: 'invalid', processing: false });
    } else if (!this.state.newSigner || this.state.newSigner.length !== 42) {
      this.setState({
        error: 'Invalid signer address format',
        processing: false,
      });
    } else {
      this.setState({
        isSigner: 'valid',
        info: 'Connecting to Gnosis Safe...',
      });
      const ethAdapter = new EthersAdapter({
        ethers,
        signerOrProvider: safeOwner,
      });

      const safeAddress =
        this.state.role === 'administrator'
          ? this.state.cfgPolygon.administrator
          : this.state.role === 'minter'
          ? this.state.cfgPolygon.master_minter
          : this.state.role === 'owner'
          ? this.state.cfgPolygon.owner
          : this.state.cfgPolygon.reserve;
      const safeService = new SafeApiKit({
        chainId: MATIC_CHAIN_ID,
      });
      const safeSdk = await Safe.create({ ethAdapter, safeAddress });
      this.setState({ info: 'Building raw transaction...' });
      let transaction = {
        ownerAddress: this.state.newSigner,
        threshold: this.state.threshold,
        nonce: this.state.nonce,
      };
      const safeTransaction = await safeSdk.createRemoveOwnerTx(transaction);
      this.setState({ info: 'Signing transaction...' });
      const safeTxHash = await safeSdk.getTransactionHash(safeTransaction);
      try {
        const senderSignature = await safeSdk.signHash(safeTxHash);
        this.setState({ info: 'Publishing proposal...' });
        await safeService.proposeTransaction({
          safeAddress,
          safeTransactionData: safeTransaction.data,
          safeTxHash,
          senderAddress: safeOwner.address,
          senderSignature: senderSignature.data,
          origin: 'SCEME PF',
        });
        //Save proposal
        this._asyncRequest = registerERC20Proposal(
          safeTxHash,
          safeOwner.address,
          this.state.role,
          this.state.description || `Remove ${this.state.role} signer`,
          'remove signer',
          { signer: this.state.newSigner, threshold: this.state.threshold },
        )
          .then((response) => {
            console.log(response.status);
          })
          .catch((error) => {
            console.log(error);
          });
        //Save operation
        this._asyncRequest = registerERC20Operation(
          safeTxHash,
          'Propose remove signer',
          `Proposal sent from ${this.state.role}`,
          safeOwner.address,
          this.state.role,
          safeTxHash,
        )
          .then((response) => {
            console.log(response.status);
          })
          .catch((error) => {
            console.log(error);
          });

        this.setState({ info: 'Proposal completed' });
        this.setState({
          safeTxHash,
          processing: false,
          displayResult: 'Proposal successfully posted in the Gnosis safe',
        });
      } catch (e) {
        console.error(e);
        this.setState({
          processing: false,
          error: 'Proposal failed to be posted in the Gnosis safe',
        });
      }
    }
  };

  fetchBalance = async (publicKeyHash) => {
    const web3 = new Web3(this.state.cfgPolygon.infura_url);
    const balance = parseFloat(
      Number(await web3.eth.getBalance(publicKeyHash)) / Math.pow(10, 18),
    ).toFixed(5);
    return balance;
  };

  updateDescription = (event) => {
    this.setState({
      descCharLeft: descLimit - event.target.value.length,
      description: event.target.value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <SuccessModal
          displayResult={this.state.displayResult}
          proposalId={this.state.safeTxHash}
          op={this.props.match.params.op}
          contract={this.props.match.params.role}
          displayAmount={this.state.displayAmount}
          aliases={this.state.aliases}
          selectedWallet={this.state.signer}
        />
        <OpModal
          pending={this.state.processing}
          info={this.state.info}
          passActive={this.state.passActive}
        />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Manage Gnosis signer"
              breadcrumbItem={`Remove a Polygon ${
                this.state.role
              } signer | Nonce ${this.state.nonce ? this.state.nonce : '-'}`}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody style={{ minHeight: '280px' }}>
                    <Row className="mt-4">
                      {this.state.owners &&
                        this.state.owners.map((owner, index) => (
                          <Col xl="3" sm="12" key={owner.value}>
                            <div className="mb-3">
                              <label
                                className="card-radio-label mb-2"
                                onClick={() => {
                                  this.connectWallet(owner.value);
                                }}
                              >
                                <input
                                  type="radio"
                                  name="wallet"
                                  id={owner.value}
                                  className="card-radio-input"
                                  readOnly
                                />

                                <div className="card-radio">
                                  <div>
                                    <h5 className="font-size-16 mb-1">
                                      <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                      <span>
                                        {this.state.aliases[
                                          owner.value.toLowerCase()
                                        ] &&
                                        this.state.aliases[
                                          owner.value.toLowerCase()
                                        ].name ? (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: this.state.aliases[
                                                owner.value.toLowerCase()
                                              ].name.replace('(', '<br/>('),
                                            }}
                                          ></span>
                                        ) : (
                                          truncStringPortion(owner.value, 8, 6)
                                        )}
                                      </span>
                                    </h5>
                                    <div>
                                      <p className="text-muted font-size-11 mb-1">
                                        {truncStringPortion(owner.value, 8, 6)}
                                      </p>
                                      <h5 className="font-size-16 mb-1">
                                        {this.state.balances[owner.value]}{' '}
                                        <PolygonLogo width="16" height="16" />
                                      </h5>
                                      <span className="text-muted">
                                        ~
                                        {Math.floor(
                                          this.state.balances[owner.value] /
                                            0.002,
                                        )}{' '}
                                        operations
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </Col>
                        ))}
                    </Row>
                    {this.state.signer && this.state.isSigner === 'valid' && (
                      <React.Fragment>
                        <Label className="mt-4">Connected acccount</Label>
                        <p>
                          <small>
                            {this.state.isSigner && 'signer: '}{' '}
                            <strong>{this.state.signer}</strong>
                          </small>
                        </p>
                      </React.Fragment>
                    )}
                    {this.state.signer && this.state.isSigner === 'invalid' && (
                      <p className="badge badge-pill badge-danger font-size-12">
                        Not connected with correct account
                      </p>
                    )}
                    {this.state.error && (
                      <p className="badge badge-danger font-size-12">
                        {this.state.error}
                      </p>
                    )}
                    <React.Fragment>
                      <Row className="mt-4">
                        <Col md="6">
                          <FormGroup className="d-block">
                            <Label>
                              Signer to remove from {this.state.role}
                            </Label>
                            <Row>
                              <Col sm="6">
                                <InputGroup className="mb-2">
                                  <input
                                    type="type"
                                    onChange={(e) =>
                                      this.setState({
                                        newSigner: e.target.value,
                                      })
                                    }
                                    placeholder={'Signer public address 0x...'}
                                    className="form-control"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="d-block">
                            <Label>Threshold {this.state.role}</Label>
                            <Row>
                              <Col sm="6">
                                <InputGroup className="mb-2">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      this.setState({
                                        threshold: e.target.value,
                                      })
                                    }
                                    placeholder={'Thresold'}
                                    value={this.state.threshold}
                                    className="form-control"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr />
                      <FormGroup>
                        <Label>Set a proposal description</Label>
                        <Row>
                          <Col sm="12">
                            <InputGroup className="mb-2">
                              <InputGroupAddon addonType="prepend">
                                <span className="input-group-text">
                                  {this.state.descCharLeft} char. left
                                </span>
                              </InputGroupAddon>
                              <Input
                                type="text"
                                className="form-control"
                                maxLength={descLimit}
                                onChange={this.updateDescription}
                                autoComplete="off"
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                      {this.state.pending && (
                        <p>
                          Follow further instructions on your ledger or
                          Metamask...
                        </p>
                      )}
                      {this.state.displayResult && (
                        <p className="badge badge-success font-size-12">
                          {this.state.displayResult}
                        </p>
                      )}
                      <Row>
                        {this.state.signer &&
                          !this.state.processing &&
                          !this.state.safeTxHash && (
                            <Col sm="8">
                              <Button color="success" onClick={this.createOP}>
                                Propose signer removal
                              </Button>
                            </Col>
                          )}
                      </Row>
                    </React.Fragment>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default PolygonRemoveGnosisSigner;

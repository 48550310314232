import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
} from 'reactstrap';
import { connect } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { fetchETHSupply, fetchPolygonSupply } from '../../helpers/api';
import ReactLoaderSpinner from 'react-loader-spinner';
import { getTimestampsPast12Months } from '../../helpers/formatter';

class Supply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeStamp: null,
      ethereum: null,
      polygon: null,
      series: null,
      months: null,
      processing: 0,
      max: 0,
    };
  }

  updtTime = (e) => {
    this.setState({ timeStamp: Date.parse(e.target.value) / 1000 });
  };

  fetchSupply = async () => {
    if (this.state.timeStamp) {
      this.setState({ max: 0, series: null, months: null });
      this.fetchTimeSerie(this.state.timeStamp);
      this.fetchMonthSerie(this.state.timeStamp);
      let data = await fetchETHSupply(this.state.timeStamp);
      this.setState({ ethereum: data.data });
      data = await fetchPolygonSupply(this.state.timeStamp);
      this.setState({ polygon: data.data });
    }
  };

  fetchTimeSerie = async (start) => {
    let i = 0,
      polygonSerie = [],
      ethereumSerie = [],
      totalSupplySerie = [],
      dates = [];
    this.setState({ max: 30 });
    while (i < 30) {
      let reducer = i * 86400;
      i++;
      this.setState({ processing: i });
      let timeStamp = start - reducer;
      let data = await fetchETHSupply(timeStamp);
      ethereumSerie.push(
        data.data && data.data.totalSupply
          ? data.data.totalSupply / 1000000
          : 0,
      );
      data = await fetchPolygonSupply(timeStamp);
      polygonSerie.push(
        data.data && data.data.totalSupply
          ? data.data.totalSupply / 1000000
          : 0,
      );
      totalSupplySerie.push(
        ethereumSerie[ethereumSerie.length - 1] +
          polygonSerie[polygonSerie.length - 1],
      );
      dates.push(
        `${new Date(timeStamp * 1000).toLocaleDateString()} ${new Date(
          timeStamp * 1000,
        ).toLocaleTimeString()}`,
      );
    }
    const series = {
      series: [
        {
          name: 'ETHEREUM',
          data: ethereumSerie.reverse(),
        },
        {
          name: 'POLYGON',
          data: polygonSerie.reverse(),
        },
        {
          name: 'TOTAL SUPPLY',
          data: totalSupplySerie.reverse(),
        },
      ],
      options: {
        chart: {
          height: 450,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.5,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#2EECC7', '#A12EEC', '#000000'],
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: 'Total Supply',
          align: 'left',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: dates.reverse(),
          title: {
            text: 'Days',
          },
        },
        yaxis: {
          title: {
            text: 'EUROP',
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
    this.setState({ series });
  };

  fetchMonthSerie = async (start) => {
    let timeStamps = getTimestampsPast12Months(start * 1000);
    let polygonSerie = [],
      ethereumSerie = [],
      totalSupplySerie = [],
      dates = [];
    for (let timeStamp of timeStamps) {
      let data = await fetchETHSupply(timeStamp / 1000);
      ethereumSerie.push(
        data.data && data.data.totalSupply
          ? data.data.totalSupply / 1000000
          : 0,
      );
      data = await fetchPolygonSupply(timeStamp / 1000);
      polygonSerie.push(
        data.data && data.data.totalSupply
          ? data.data.totalSupply / 1000000
          : 0,
      );
      totalSupplySerie.push(
        ethereumSerie[ethereumSerie.length - 1] +
          polygonSerie[polygonSerie.length - 1],
      );
      dates.push(
        `${new Date(timeStamp).toLocaleDateString()} ${new Date(
          timeStamp,
        ).toLocaleTimeString()}`,
      );
    }
    const months = {
      series: [
        {
          name: 'ETHEREUM',
          data: ethereumSerie,
        },
        {
          name: 'POLYGON',
          data: polygonSerie,
        },
        {
          name: 'TOTAL SUPPLY',
          data: totalSupplySerie,
        },
      ],
      options: {
        chart: {
          height: 450,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.5,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#2EECC7', '#A12EEC', '#000000'],
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: 'Total Supply',
          align: 'left',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: dates,
          title: {
            text: 'Days',
          },
        },
        yaxis: {
          title: {
            text: 'EUROP',
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
    this.setState({ months });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Quote"
              breadcrumbItem={`Total Supply by date`}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-2">Select date</CardTitle>
                    <Row className="mb-2">
                      <Col md="2">
                        <input
                          type="datetime-local"
                          className="form-control"
                          onChange={this.updtTime}
                        />
                      </Col>
                      <Col md="6">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={() => {
                            this.fetchSupply();
                          }}
                        >
                          Fetch supply
                        </button>
                      </Col>
                    </Row>
                    <React.Fragment>
                      <Table className="table-nowrap table-centered mt-5">
                        <thead>
                          <tr>
                            <th scope="col">EUROP Supply</th>
                            <th scope="col">Block height</th>
                            <th scope="col">Network</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.ethereum && (
                            <tr>
                              <td>
                                {parseFloat(
                                  this.state.ethereum.totalSupply / 1000000,
                                ).toLocaleString()}{' '}
                                <small>EUROP</small>
                              </td>
                              <td>
                                <strong>
                                  {this.state.ethereum.blockNumber}
                                </strong>
                              </td>
                              <td>Ethereum</td>
                            </tr>
                          )}
                          {this.state.polygon && (
                            <tr>
                              <td>
                                {parseFloat(
                                  this.state.polygon.totalSupply / 1000000,
                                ).toLocaleString()}{' '}
                                <small>EUROP</small>
                              </td>
                              <td>
                                <strong>
                                  {this.state.polygon.blockNumber}
                                </strong>
                              </td>
                              <td>Polygon</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot>
                          {this.state.polygon && this.state.ethereum && (
                            <tr>
                              <td colSpan={3}>
                                <small>
                                  <strong>TOTAL SUPPLY:</strong>
                                </small>{' '}
                                {parseFloat(
                                  this.state.ethereum.totalSupply / 1000000 +
                                    this.state.polygon.totalSupply / 1000000,
                                ).toLocaleString()}{' '}
                                <small>EUROP</small>
                              </td>
                            </tr>
                          )}
                        </tfoot>
                      </Table>
                    </React.Fragment>
                    {this.state.series ? (
                      <>
                        <hr className="mt-5" />
                        <h5>Last 30 days</h5>
                        <ReactApexChart
                          options={this.state.series.options}
                          series={this.state.series.series}
                          type="line"
                          height={450}
                        />
                      </>
                    ) : (
                      this.state.timeStamp &&
                      this.state.max > 0 && (
                        <React.Fragment>
                          <ReactLoaderSpinner
                            type="Puff"
                            color="#50a5f1"
                            height={50}
                            width={50}
                          />
                          <p className="mt-2 text-info">
                            <strong>
                              Processing time series...{' '}
                              {parseInt(
                                (this.state.processing / this.state.max) * 100,
                              )}
                              %
                            </strong>
                          </p>
                        </React.Fragment>
                      )
                    )}
                    {this.state.months && this.state.series && (
                      <>
                        <hr className="mt-5" />
                        <h5>Last 12 months</h5>
                        <ReactApexChart
                          options={this.state.months.options}
                          series={this.state.months.series}
                          type="line"
                          height={450}
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default connect(mapStatetoProps, null)(Supply);

import 'react-datepicker/dist/react-datepicker.css';

import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';

import './datatables.scss';
import { truncStringPortion } from '../../helpers/formatter.js';
import CSV from '../../components/Common/CSV';
import {
  dictAliases,
  fetchERC20config,
  gnosisSafeInfo,
  gnosisSafeTxInfo,
  gnosisSafeTxs,
} from '../../helpers/api';
import { ETH_CHAIN_ID, PLATFORM } from '../../constants.js';

class EthProposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decimals: 100,
      startDate: new Date(),
      activeTab: '1',
      csvRows: [],
      cfgEth: {},
      aliases: [],
      transactions: {
        columns: [
          {
            label: 'Proposal Hash',
            field: 'proposalId',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Nonce',
            field: 'nonce',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Date',
            field: 'date',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Operation',
            field: 'operation',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Parameters',
            field: 'parameters',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Approvals',
            field: 'approvals',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Rejects',
            field: 'rejects',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Actions',
            field: 'action',
            sort: 'asc',
            width: 100,
          },
        ],
        rows: [],
      },
      limit: false,
      loading: true,
      threshold: '-',
    };
    this.handleChange.bind(this);
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  componentDidMount = async () => {
    let aliases = await dictAliases();
    this.setState({ aliases: aliases.data });
    let cfgEth = await fetchERC20config();
    await this.setState({ cfgEth: cfgEth.data });
    const role = this.props.match.params.role;
    const safeAddr =
      role === 'administrator'
        ? this.state.cfgEth.administrator
        : role === 'master_minter'
        ? this.state.cfgEth.master_minter
        : role === 'owner'
        ? this.state.cfgEth.owner
        : this.state.cfgEth.reserve;
    // const transactionServiceUrl = this.state.cfgEth.safe_tx_url;
    let resp = await gnosisSafeInfo(ETH_CHAIN_ID, safeAddr);
    const threshold = resp.data.threshold;
    this.setState({ threshold });
    resp = await gnosisSafeTxs(ETH_CHAIN_ID, safeAddr);
    resp = resp.data;
    for (let tx of resp.results.sort(
      (a, b) =>
        parseInt(b.transaction.executionInfo.nonce) -
        parseInt(a.transaction.executionInfo.nonce),
    )) {
      tx.nonce = tx.transaction.executionInfo.nonce;
      const txInfo = (await gnosisSafeTxInfo(ETH_CHAIN_ID, tx.transaction.id))
        .data;
      tx.dataDecoded = txInfo.txData.dataDecoded;
      tx.safeTxHash = txInfo.detailedExecutionInfo.safeTxHash;
      tx.confirmations = txInfo.detailedExecutionInfo.confirmations;
      tx.confirmationsRequired =
        txInfo.detailedExecutionInfo.confirmationsRequired;
      tx.rejectors = txInfo.detailedExecutionInfo.rejectors;
      if (
        !this.state.transactions ||
        !this.state.transactions.rows.some((row) => row.nonce === tx.nonce)
      ) {
        const rejects =
          !tx.dataDecoded &&
          tx.confirmations &&
          tx.confirmations.length >= tx.confirmationsRequired
            ? tx.confirmations
            : [];
        const accepts = rejects.length === 0 ? tx.confirmations : [];
        let row = {
          proposalId: truncStringPortion(tx.safeTxHash, 8, 8),
          nonce: tx.nonce,
          date: new Date(
            txInfo.detailedExecutionInfo.submittedAt,
          ).toLocaleString(),
          operation: tx.dataDecoded ? (
            <b>{tx.dataDecoded.method}</b>
          ) : txInfo.txData.value !== '0' ? (
            <b>ETH Transfer</b>
          ) : (
            <b className="text-danger">On-Chain Rejection</b>
          ),
          parameters: (
            <>
              {tx.dataDecoded &&
                tx.dataDecoded.parameters.map(
                  (e) =>
                    e.name !== 'prevOwner' && (
                      <small key={e.name} className="d-block">
                        {e.type === 'uint256' && e.name !== '_threshold'
                          ? 'amount'
                          : e.name}{' '}
                        :{' '}
                        {e.type === 'address' ? (
                          this.state.aliases[e.value.toLowerCase()] &&
                          this.state.aliases[e.value.toLowerCase()].name ? (
                            <>
                              {this.state.aliases[e.value.toLowerCase()].name}
                              <br />
                              <small>{e.value}</small>
                            </>
                          ) : (
                            e.value
                          )
                        ) : e.type === 'uint256' && e.name !== '_threshold' ? (
                          `${(
                            e.value / this.state.cfgEth.decimals
                          ).toLocaleString()} EUROP`
                        ) : (
                          e.value
                        )}
                      </small>
                    ),
                )}
              {parseFloat(txInfo.txData.value) > 0 && (
                <small className="d-block">
                  {parseFloat(txInfo.txData.value) / 10 ** 18}ETH
                </small>
              )}
            </>
          ),
          approvals: (
            <small>
              {accepts.map(
                (confirmation, index) =>
                  confirmation.signer && (
                    <span key={`appr-${index}`} className="d-block">
                      {this.state.aliases[
                        confirmation.signer.value.toLowerCase()
                      ] &&
                      this.state.aliases[
                        confirmation.signer.value.toLowerCase()
                      ].name
                        ? this.state.aliases[
                            confirmation.signer.value.toLowerCase()
                          ].name
                        : truncStringPortion(confirmation.signer.value, 8, 8)}
                    </span>
                  ),
              )}
            </small>
          ),
          rejects: (
            <small>
              {rejects.map(
                (confirmation, index) =>
                  confirmation.signer && (
                    <span key={`rejc-${index}`} className="d-block">
                      {this.state.aliases[
                        confirmation.signer.value.toLowerCase()
                      ] &&
                      this.state.aliases[
                        confirmation.signer.value.toLowerCase()
                      ].name
                        ? this.state.aliases[
                            confirmation.signer.value.toLowerCase()
                          ].name
                        : truncStringPortion(confirmation.signer.value, 8, 8)}
                    </span>
                  ),
              )}
            </small>
          ),
          status: (
            <b>
              {tx.isExecuted
                ? 'Closed'
                : tx.confirmations.length >= tx.confirmationsRequired
                ? 'Closed'
                : 'Pending'}
              <br />
              {tx.confirmations.length >= threshold ? (
                txInfo.executedAt ? (
                  <span className="badge badge-success font-size-10">
                    Completed
                  </span>
                ) : (
                  <span className="badge badge-info font-size-10">
                    Awaiting execution
                  </span>
                )
              ) : (
                <span className="badge badge-warning font-size-10">Open</span>
              )}
            </b>
          ),
          action:
            txInfo.executedAt && tx.dataDecoded ? (
              <Link
                as="span"
                className="badge badge-primary font-size-11"
                style={{ cursor: 'pointer' }}
                to={`/eth-proposal/${role}/${tx.safeTxHash}`}
              >
                View Details
              </Link>
            ) : tx.confirmations.length >= tx.confirmationsRequired &&
              tx.dataDecoded ? (
              <Link
                as="span"
                className="badge badge-primary font-size-11"
                style={{ cursor: 'pointer' }}
                to={`/eth-proposal/${role}/${tx.safeTxHash}`}
              >
                View Details
              </Link>
            ) : (
              tx.dataDecoded && (
                <Link
                  as="span"
                  className="badge badge-warning font-size-11"
                  style={{ cursor: 'pointer' }}
                  to={`/eth-proposal/${role}/${tx.safeTxHash}`}
                >
                  Accept / Reject
                </Link>
              )
            ),
        };
        let csvRow = {
          proposalId: tx.safeTxHash,
          nonce: tx.nonce,
          date: new Date(
            txInfo.detailedExecutionInfo.submittedAt,
          ).toISOString(),
          operation: tx.dataDecoded && tx.dataDecoded.method,
          parameters:
            tx.dataDecoded &&
            tx.dataDecoded.parameters.map(
              (e) =>
                e.name !== 'prevOwner' && `[${e.name} (${e.type}): ${e.value}]`,
            ),
          approvals: accepts.map((confirmation, index) =>
            confirmation.signer &&
            this.state.aliases[confirmation.signer.value.toLowerCase()]
              ? `${
                  this.state.aliases[confirmation.signer.value.toLowerCase()].name
                } (${confirmation.signer.value})`
              : confirmation.signer && `${confirmation.signer.value}`,
          ),
          rejects: rejects.map((confirmation, index) =>
            confirmation.signer &&
            this.state.aliases[confirmation.signer.value.toLowerCase()]
              ? `${
                  this.state.aliases[confirmation.signer.value.toLowerCase()].name
                } (${confirmation.signer.value})`
              : confirmation.signer && `${confirmation.signer.value}`,
          ),
          status: txInfo.executedAt
            ? accepts > rejects
              ? 'Accepted'
              : 'Rejected'
            : 'Open',
          action:
            txInfo.txHash && PLATFORM === 'staging'
              ? `https://sepolia.etherscan.io/tx/${txInfo.txHash}`
              : txInfo.txHash && `https://etherscan.io/tx/${txInfo.txHash}`,
        };

        this.setState({
          transactions: {
            columns: [...this.state.transactions.columns],
            rows: [...this.state.transactions.rows, row],
          },
          csvRows: [...this.state.csvRows, csvRow],
          loading: false,
        });
      } else if (this.state.transactions) {
        let row = this.state.transactions.rows.filter(
          (row) => row.nonce === tx.nonce,
        )[0];
        let csvRow = this.state.csvRows.filter(
          (row) => row.nonce === tx.nonce,
        )[0];

        row.operation = tx.dataDecoded && (
          <b className="text-danger">On-Chain Rejection</b>
        );
        row.parameters = (
          <>
            {tx.dataDecoded &&
              tx.dataDecoded.parameters.map(
                (e) =>
                  e.name !== 'prevOwner' && (
                    <small key={e.name} className="d-block">
                      {e.type === 'uint256' && e.name !== '_threshold'
                        ? 'amount'
                        : e.name}{' '}
                      :{' '}
                      {e.type === 'address' ? (
                        this.state.aliases[e.value.toLowerCase()] &&
                        this.state.aliases[e.value.toLowerCase()].name ? (
                          <>
                            {this.state.aliases[e.value.toLowerCase()].name}
                            <br />
                            <small>{e.value}</small>
                          </>
                        ) : (
                          e.value
                        )
                      ) : e.type === 'uint256' && e.name !== '_threshold' ? (
                        `${(
                          e.value / this.state.cfgEth.decimals
                        ).toLocaleString()} EUROP`
                      ) : (
                        e.value
                      )}
                    </small>
                  ),
              )}
            {parseFloat(tx.value) > 0 && (
              <small className="d-block">
                {parseFloat(tx.value) / 10 ** 18}ETH
              </small>
            )}
          </>
        );
        row.status = (
          <b>
            {tx.isExecuted
              ? 'Closed'
              : csvRow.approvals.length >= threshold ||
                csvRow.rejects.length >= threshold
              ? 'Closed'
              : 'Pending'}
            <br />
            {csvRow.approvals.length >= threshold ||
            csvRow.rejects.length >= threshold ? (
              <span className="badge badge-success font-size-10">
                Completed
              </span>
            ) : tx.isExecuted ? (
              <span className="badge badge-success font-size-10">
                Completed
              </span>
            ) : (
              <span className="badge badge-warning font-size-10">Open</span>
            )}
          </b>
        );
        row.action = tx.isExecuted ? (
          <Link
            as="span"
            className="badge badge-primary font-size-11"
            style={{ cursor: 'pointer' }}
            to={`/eth-proposal/${role}/${csvRow.proposalId}`}
          >
            View Details
          </Link>
        ) : csvRow.approvals.length >= threshold ||
          csvRow.rejects.length >= threshold ? (
          <Link
            as="span"
            className="badge badge-primary font-size-11"
            style={{ cursor: 'pointer' }}
            to={`/eth-proposal/${role}/${csvRow.proposalId}`}
          >
            View Details
          </Link>
        ) : (
          <Link
            as="span"
            className="badge badge-danger font-size-11"
            style={{ cursor: 'pointer' }}
            to={`/eth-proposal/${role}/${csvRow.proposalId}?action=reject`}
          >
            Close reject
          </Link>
        );

        csvRow.operation = tx.dataDecoded && tx.dataDecoded.method;
        csvRow.parameters =
          tx.dataDecoded &&
          tx.dataDecoded.parameters.map(
            (e) =>
              e.name !== 'prevOwner' && `[${e.name} (${e.type}): ${e.value}]`,
          );
        csvRow.status = txInfo.executedAt
          ? 'Closed'
          : csvRow.approvals.length >= threshold ||
            csvRow.rejects.length >= threshold
          ? 'Closed'
          : 'Pending';
        csvRow.action = `https://${window.location.hostname}/eth-proposal/${role}/${csvRow.proposalId}`;

        let rows = this.state.transactions.rows;
        rows.pop();
        let csvRows = this.state.csvRows;
        csvRows.pop();
        this.setState({
          transactions: {
            columns: [...this.state.transactions.columns],
            rows: [...rows, row],
          },
          csvRows: [...csvRows, csvRow],
          loading: false,
        });
      }
    }
    setTimeout(() => {
      let cRows = [];
      for (let cR of this.state.csvRows) {
        console.log(cR);
        cR.parameters =
          cR.parameters && cR.parameters.toString().split(',').join(' ');
        cR.approvals =
          cR.approvals && cR.approvals.toString().split(',').join(' ');
        cR.rejects = cR.rejects && cR.rejects.toString().split(',').join(' ');
        cRows.push(cR);
      }
      this.setState({ csvRows: cRows });
    }, 200);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {!this.state.loading && (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <h4 className="card-title">
                        ERC20{' '}
                        {this.props.match.params.role === 'administrator'
                          ? 'Administrator'
                          : this.props.match.params.role === 'master_minter'
                          ? 'Master minter'
                          : this.props.match.params.role === 'owner'
                          ? 'Owner'
                          : 'Reserve'}{' '}
                        proposals |{' '}
                        <small>Threshold {this.state.threshold}</small>
                      </h4>
                      <TabContent activeTab={'1'} className="p-3">
                        <TabPane tabId="1" id="all-order">
                          <MDBDataTable
                            responsive
                            bordered
                            data={this.state.transactions}
                            className="mt-1"
                            hover
                          />
                          <CSV
                            name={`${this.props.match.params.role}_proposals`}
                            transactions={this.state.transactions}
                            csvRows={this.state.csvRows}
                          />
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {this.state.loading && (
              <div className="text-center my-3">
                <Link to="#" className="text-primary">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading{' '}
                </Link>
              </div>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default EthProposals;

export const truncStringPortion = (
  str,
  firstCharCount = str.length,
  endCharCount = 0,
  dotCount = 3,
) => {
  if (str === undefined) return str;
  let convertedStr = '';
  convertedStr += str.substring(0, firstCharCount);
  convertedStr += '.'.repeat(dotCount);
  convertedStr += str.substring(str.length - endCharCount, str.length);
  return convertedStr;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const normalizeString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const convertUTCDateToLocalDate = (date) => {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};

export const convertErrorCode = (contract, code) => {
  const intCode = parseInt(code) - 1;
  if (contract === 'reserve') {
    const reserveError = [
      'Unreferenced signing address',
      'Proposal Id already referenced',
      'Proposal Id not referenced',
      'Open proposals limit reached',
      'Proposal already closed',
      'Transfer amount not permitted',
      'Proposal already accepted',
    ];
    return reserveError[intCode];
  } else if (contract === 'administrator') {
    const adminError = [
      'Unreferenced signing address',
      'Proposal Id already referenced',
      'Proposal Id not referenced',
      'Open proposals limit reached',
      'Proposal already closed',
      'Operation not permitted',
      'Transfer amount not permitted',
      'Proposal already accepted',
    ];
    return adminError[intCode];
  } else if (contract === 'minter') {
    const minterError = [
      'Unreferenced signing address',
      'Proposal Id already referenced',
      'Proposal Id not referenced',
      'Open proposals limit reached',
      'Proposal already closed',
      'Operation not permitted',
      'Mint/burn amount not permitted',
      'Proposal already accepted',
    ];
    return minterError[intCode];
  } else if (contract === 'owner') {
    const ownerError = [
      'Unreferenced signing address',
      'Proposal Id already referenced',
      'Proposal Id not referenced',
      'Open proposals limit reached',
      'Proposal already closed',
      'Operation not permitted',
      'Proposal already accepted',
    ];
    return ownerError[intCode];
  } else if (contract === 'token') {
    const salvusError = [
      'Contract is paused',
      'Only admin allowed',
      'Only owner allowed',
      'Only minter allowed',
      'Address is not referenced',
      'Address is locked',
      'Address is not white listed',
      'Not allowed to transfer for Reserve',
      'Not allowed to transfer for this address',
      'Insufficient balance',
      'Insufficient balance for reserve',
      'Invalid minting',
    ];
    return salvusError[intCode];
  }
  return 'Unknown';
};

export const makeRefId = (length) => {
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength)),
    );
  }
  return result.join('');
};

export const getTimestampsPast12Months = (timestamp) => {
    const currentDate = new Date(timestamp);
    const timestamps = [];
  
    const originalHours = currentDate.getHours();
    const originalMinutes = currentDate.getMinutes();
    const originalSeconds = currentDate.getSeconds();
    const originalMilliseconds = currentDate.getMilliseconds();
  
    for (let i = 0; i < 12; i++) {
      const date = new Date(currentDate);
      date.setMonth(currentDate.getMonth() - i);
  
      // Preserve the original time
      date.setHours(originalHours);
      date.setMinutes(originalMinutes);
      date.setSeconds(originalSeconds);
      date.setMilliseconds(originalMilliseconds);
  
      timestamps.push(date.getTime()); // Get the timestamp in milliseconds
    }
  
    return timestamps.reverse();
  }

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { fetchPolygonconfig, gnosisSafePendingTxs } from '../../helpers/api';
import PolygonLogo from '../../components/Common/PolygonLogo.js';
import { MATIC_CHAIN_ID } from '../../constants.js';

class SidebarPolygonProposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countMinter: 0,
      countAdmin: 0,
      countOwner: 0,
      countReserve: 0,
      total: 0,
      cfgPolygon: {},
    };
  }

  componentDidMount = async () => {
    let cfgPolygon = await fetchPolygonconfig();
    await this.setState({ cfgPolygon: cfgPolygon.data });
    this.loadCounter();
    this._interval = setInterval(() => {
      this.loadCounter();
    }, 30000);
  };

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  loadCounter = async () => {
    if (this.state.cfgPolygon) {
      //const transactionServiceUrl = this.state.cfgEth.safe_tx_url
      let mtx = 0,
        atx = 0,
        otx = 0,
        rtx = 0;
      try {
        let respmtx = await gnosisSafePendingTxs(
          MATIC_CHAIN_ID,
          this.state.cfgPolygon.master_minter,
        );
        mtx = respmtx.data.results.length;
        let respatx = await gnosisSafePendingTxs(
            MATIC_CHAIN_ID,
          this.state.cfgPolygon.administrator,
        );
        atx = respatx.data.results.length;
        let respotx = await gnosisSafePendingTxs(
            MATIC_CHAIN_ID,
          this.state.cfgPolygon.owner,
        );
        otx = respotx.data.results.length;
        let resprtx = await gnosisSafePendingTxs(
            MATIC_CHAIN_ID,
          this.state.cfgPolygon.reserve,
        );
        rtx = resprtx.data.results.length;
      } catch (e) {
        console.log(e);
      }
      this.setState({
        countMinter: mtx,
        countAdmin: atx,
        countOwner: otx,
        countReserve: rtx,
        total: mtx + atx + otx + rtx,
      });
    }
  };

  render() {
    return (
      <li>
        <Link to="/#" className="has-arrow  waves-effect">
          <i>
            <PolygonLogo width="16" height="16" />
          </i>
          {this.state.total > 0 && (
            <span className="badge badge-pill badge-success float-right text-dark">
              {this.state.total}
            </span>
          )}
          <span>Polygon Proposals</span>
        </Link>
        <ul className="sub-menu">
          <li>
            <Link to="/polygon-proposals/administrator">
              {this.state.countAdmin > 0 && (
                <span className="badge badge-pill badge-success float-right text-dark">
                  {this.state.countAdmin}
                </span>
              )}
              Administrator
            </Link>
          </li>
          <li>
            <Link to="/polygon-proposals/master_minter">
              {this.state.countMinter > 0 && (
                <span className="badge badge-pill badge-success float-right text-dark">
                  {this.state.countMinter}
                </span>
              )}
              Minter
            </Link>
          </li>
          <li>
            <Link to="/polygon-proposals/reserve">
              {this.state.countReserve > 0 && (
                <span className="badge badge-pill badge-success float-right text-dark">
                  {this.state.countReserve}
                </span>
              )}
              Reserve
            </Link>
          </li>
          <li>
            <Link to="/polygon-proposals/owner">
              {this.state.countOwner > 0 && (
                <span className="badge badge-pill badge-success float-right text-dark">
                  {this.state.countOwner}
                </span>
              )}
              Owner
            </Link>
          </li>
        </ul>
      </li>
    );
  }
}

export default SidebarPolygonProposals;

import React, { Component } from 'react';
import { Modal, FormGroup, Button } from 'reactstrap';

import SVGLogo from '../../../components/Common/SVGLogo.js';
import {
  truncStringPortion,
  capitalizeFirstLetter,
} from '../../../helpers/formatter.js';

class SuccessModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: true,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          size="xl"
          isOpen={this.props.displayResult && this.state.display}
          centered={true}
          backdrop="static"
          wrapClassName="modal-balance"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0 text-muted" id="myLargeModalLabel">
              Proposal successfully sent
            </h5>
          </div>
          {this.props.displayResult && (
            <div className="modal-body">
              <FormGroup>
                <p className="mt-1 font-size-14">
                  <i className="bx bx-info-circle text-success font-size-18 mr-1"></i>
                  Proposal ID <br />
                  <b className="font-size-12 mr-1">{this.props.proposalId}</b>
                  <br />
                  for operation <b>{this.props.op}</b> sent from
                  <b className="ml-1">
                    {capitalizeFirstLetter(this.props.contract)}
                  </b>{' '}
                  {this.props.displayAmount > 0 && (
                    <span>
                      <br />
                      of{' '}
                      <b>
                        <SVGLogo width="16" height="16" />
                        {this.props.displayAmount}
                      </b>
                    </span>
                  )}{' '}
                  with
                  <b className="ml-1">
                    {this.props.aliases[
                      this.props.selectedWallet.toLowerCase()
                    ] &&
                    this.props.aliases[this.props.selectedWallet.toLowerCase()]
                      .name
                      ? this.props.aliases[
                          this.props.selectedWallet.toLowerCase()
                        ].name
                      : truncStringPortion(this.props.selectedWallet, 8, 6)}
                  </b>
                </p>
                <p className="text-muted mt-1">
                  It may take up to 1 min. before validation on the network
                </p>
                <Button
                  className="mt-2"
                  type="button"
                  color="dark"
                  onClick={() => {
                    this.props.redirect? this.props.redirect() : this.setState({ display: false });
                  }}
                >
                  Close
                </Button>
              </FormGroup>
            </div>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default SuccessModal;
